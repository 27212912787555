import React, { FC } from "react";
import { ColumnType, KeyFact, KeyFactsNumberProps } from "../generated-types";
import { SerializedItem } from "~/foundation/Jss";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { Box, Flex, Heading, Text as ChakraText } from "@chakra-ui/react";
import { ExtendedBox } from "~/foundation/Components/ExtendedBox";
import { themable } from "~/foundation/Theme";
import * as variantThemes from "../KeyFactsText/variants";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { GenericHeader } from "~/foundation/Components/GenericHeader";

const KeyFactsNumber: FC<KeyFactsNumberProps> = ({ params, rendering, fields }) => {

	const children = rendering.children || [];

	if (children.length === 0){
		return <></>
	}
	if (rendering.customData?.columnType === ColumnType.OneColumn || rendering.customData?.columnType === ColumnType.TwoColumn) {
		return (
			<ContentWrapper py={["0", null]} pb={["12", null, "16"]}>
				<RenderKeyFactsNumber params={params} rendering={rendering} />
			</ContentWrapper>
		);
	}

	return (
		<Ribbon py={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}>
			<ContentWrapper py={["0", null]}>
				<GenericHeader {...fields}/>
			</ContentWrapper>
			<ContentWrapper py={["0", null]}>
				<RenderKeyFactsNumber params={params} rendering={rendering} />
			</ContentWrapper>
		</Ribbon>
	);
};

const RenderKeyFactsNumber: FC<KeyFactsNumberProps> = ({ params, rendering }) => {
	const children = rendering.children || [];
	const twoColumn = params.twoColumn === "1";

	return (
		<Flex
			gap="10"
			flexWrap="wrap"
		>
			{children.map((keyFact: SerializedItem<KeyFact>) => {
				const fields = keyFact.fields;
				if (!fields) {
					return <></>;
				}

				return (
					<Box
						key={keyFact.id}
						pb="6"
						borderBottomWidth="1px"
						borderColor="primary.neutralGrey"
						flexShrink="0"
						flexGrow="0"
						flexBasis={twoColumn ? ["100%", null, null, "calc(50% - 1.25rem)"] : ["100%", "calc(50% - 1.25rem)", null, "calc(33.333% - 1.667rem)"]}
					>
						<Flex alignItems="flex-start" justifyContent="space-between">
							<Box flexBasis={twoColumn ? "65%" : "50%"} mt="2">
								{fields?.factDescription?.value &&
									<ExtendedBox mb="2">
										<ChakraText variant="footerBody" dangerouslySetInnerHTML={{ __html: fields.factDescription.value }} />
									</ExtendedBox>
								}
								{fields?.factSubDescription?.value &&
									<ExtendedBox>
										<ChakraText variant="footerBody" dangerouslySetInnerHTML={{ __html: fields.factSubDescription.value }} />
									</ExtendedBox>
								}
							</Box>
							{fields?.factNumber?.value &&
								<ExtendedBox variant="themed" flexBasis={twoColumn ? "35%" : "50%"} textAlign="end">
									<Heading as="h3" size={["xl", null, null, "lg"]} dangerouslySetInnerHTML={{ __html: fields.factNumber.value }} />
								</ExtendedBox>
							}
						</Flex>
					</Box>
				)
			})}
		</Flex>
	)
};

export default themable({ variantThemes })(KeyFactsNumber);